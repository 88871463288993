import { Modal } from 'antd'
import { useEffect, useState } from 'react'

import { terminalItem } from '@/store'

import { API_URL } from '@/configs'

import { TerminalListItem } from './TerminalListItem'
import { AddNewStatus } from './modal'

export type currentTerminalType = {
	id: string
	status: string
}

export function TerminalList({
	listTerminals,
	fetch,
}: {
	listTerminals: Array<terminalItem> | null
	fetch: () => void
}) {
	const [addNewStatusIsOpen, setAddNewStatusIsOpen] = useState(false)
	const [currentTerminal, setCurrentTerminal] =
		useState<null | currentTerminalType>(null)
	const [statuses, setStatuses] = useState<null | any>(null)

	const closeModal = () => {
		setAddNewStatusIsOpen(false)
	}

	const onSetStatus = (id: string, status: string) => {
		setCurrentTerminal({ id, status })
		setAddNewStatusIsOpen(true)
	}

	useEffect(() => {
		const socket = new WebSocket(`${API_URL}/terminals/get_terminals_statuses`)
		socket.addEventListener('message', (event) => {
			setStatuses(JSON.parse(event.data))
		})

		return () => socket.close()
	}, [])

	return (
		<>
			{listTerminals && listTerminals?.length > 0 ? (
				<ul className="terminals__list">
					{listTerminals.map((item, index) => (
						<li className="terminals__item" key={item.id}>
							<TerminalListItem
								item={item}
								onSetStatus={onSetStatus}
								fetch={fetch}
								statuses={statuses ? statuses[index] : null}
							/>
						</li>
					))}
				</ul>
			) : (
				<div>Нет ни одного терминала</div>
			)}
			<Modal
				title="Установка статуса"
				open={addNewStatusIsOpen}
				onOk={closeModal}
				onCancel={closeModal}
				footer={null}
			>
				<AddNewStatus
					currentTerminal={currentTerminal}
					fetch={fetch}
					onClose={closeModal}
				/>
			</Modal>
		</>
	)
}
