import { Button, notification } from 'antd'
import { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import {
	setIsCalling,
	setTypeCall,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import { ICallBody } from '@/utils'

import { API_URL } from '@/configs'

import { terminalCallAPI } from '@/api'

interface Props {
	children: JSX.Element
}

export const CallProvider = ({ children }: Props) => {
	const { userId } = useAppSelector((state) => state.auth)
	const { isCalling } = useAppSelector((state) => state.app)
	const dispatch = useAppDispatch()
	const router = useNavigate()
	const notificationRef = useRef<any>(null)

	useEffect(() => {
		const socket = new WebSocket(
			`${API_URL}/terminal_call/subscribe?UserId=${userId}`
		)

		socket.onmessage = async (msg: any) => {
			if (msg.data !== 'null') {
				const data: ICallBody = JSON.parse(msg.data)
				if (!isCalling) {
					dispatch(setIsCalling(true))
					dispatch(setTypeCall('terminal'))

					if (!notificationRef.current) {
						notificationRef.current = notification.open({
							key: 'call_notification',
							message: 'Звонок поступил!',
							description: 'У вас новый звонок. Хотите принять или отклонить?',
							btn: [
								<Button
									key="accept"
									type="primary"
									onClick={() => handleAccept(data.terminal.terminalId)}
								>
									Принять
								</Button>,
								<Button key="reject" onClick={handleReject}>
									Отклонить
								</Button>,
							],
							onClose: () => console.log('Notification closed'),
							duration: 0,
						})
					}
				}
			} else {
				closeNotification()
			}
		}

		return () => {
			if (socket?.readyState !== 3) socket.close()
		}
	}, [isCalling, userId, dispatch])

	const closeNotification = () => {
		notification.destroy('call_notification')
		notificationRef.current = null
	}

	const handleAccept = (terminalId: string) => {
		dispatch(setIsCalling(false))
		terminalCallAPI.accept_call()
		router(`/webrtc-session/${terminalId}`)

		closeNotification()
	}

	const handleReject = () => {
		dispatch(setIsCalling(false))
		terminalCallAPI.reject_call()

		closeNotification()
	}

	return <>{children}</>
}
