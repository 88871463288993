import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type journalsEvent = {
	dateTimeCreation: string
	eventType: string
	importance: string
	message: string
	id: string
	key: string
}

interface InitialStateType {
	journalList: Array<journalsEvent> | undefined
	eventType: Array<{ id: number; type: string }> | null
	importanceType: Array<{ id: number; type: string }> | null
}

const initialState: InitialStateType = {
	journalList: undefined,
	eventType: null,
	importanceType: null,
}

export const journalsReducer = createSlice({
	name: 'journals',
	initialState,
	reducers: {
		setJournal: (state, action: PayloadAction<Array<any>>) => {
			state.journalList = action.payload
		},
		setImportanceType: (
			state,
			action: PayloadAction<Array<{ id: number; type: string }>>
		) => {
			state.importanceType = action.payload
		},
		setEventType: (
			state,
			action: PayloadAction<Array<{ id: number; type: string }>>
		) => {
			state.eventType = action.payload
		},
	},
})

export const { setJournal, setImportanceType, setEventType } =
	journalsReducer.actions

export default journalsReducer.reducer
