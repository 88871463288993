import AppLayout from 'layout/AppLayout'
import MainLayout from 'layout/MainLayout'
import { createBrowserRouter } from 'react-router-dom'

import { Error } from '@/components'

import {
	Administration,
	Login,
	OperatorPage,
	ReportsPage,
	SettingsPage,
	TerminalPage,
	TerminalsPage,
} from '@/pages'

import {
	ADMIN_PATH,
	ERROR_PATH,
	MAIN_PATH,
	OPERATOR_PATH,
	REPORTS_PATH,
	SETTINGS_PATH,
	TERMINALS_PATH,
} from '@/utils'

const router = createBrowserRouter([
	{
		path: MAIN_PATH,
		element: <Login />,
		errorElement: <Error />,
	},
	{
		path: MAIN_PATH,
		element: <AppLayout />,
		errorElement: <Error />,
		children: [
			{
				path: MAIN_PATH,
				element: <MainLayout />,
				errorElement: <Error />,

				children: [
					{
						path: TERMINALS_PATH,
						element: <TerminalsPage />,
						errorElement: <Error />,
					},
					{
						path: `${TERMINALS_PATH}/:id`,
						element: <TerminalPage />,
						errorElement: <Error />,
					},
					{
						path: ADMIN_PATH,
						element: <Administration />,
						errorElement: <Error />,
					},
					{
						path: `${OPERATOR_PATH}/:id`,
						element: <OperatorPage />,
						errorElement: <Error />,
					},
					{
						path: REPORTS_PATH,
						element: <ReportsPage />,
						errorElement: <Error />,
					},
					{
						path: SETTINGS_PATH,
						element: <SettingsPage />,
						errorElement: <Error />,
					},
				],
			},
		],
	},

	{
		path: ERROR_PATH,
		element: <Error />,
	},
])

export default router
