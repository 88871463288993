import { configureStore } from '@reduxjs/toolkit'

import abonentsReducer from './abonents/abonentsSlice'
import appReducer from './app/appSlice'
import appealsReducer from './appeals/appealsSlice'
import authReducer from './auth/authSlice'
import journalsReducer from './journals/journalsSlice'
import terminalsReducer from './terminals/terminalsSlice'
import usersReducer from './users/usersSlice'

export const store = configureStore({
	reducer: {
		app: appReducer,
		auth: authReducer,
		terminals: terminalsReducer,
		users: usersReducer,
		appeals: appealsReducer,
		abonents: abonentsReducer,
		journals: journalsReducer,
	},

	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),

	devTools: process.env.NODE_ENV !== 'production',
})

//@ts-ignore
window.store = store
export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
