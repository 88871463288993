import { Button, Modal } from 'antd'
import { API_URL } from 'configs/url'
import { ReactNode, useEffect, useState } from 'react'

import { RtspSession, VncSession, WebRTCSession } from '@/components'

import { setTypeCall, useAppDispatch, useAppSelector } from '@/store'

import { terminalCallAPI } from '@/api'

import PrinterIcon from '../../../assets/images/print.svg'
import ScannerIcon from '../../../assets/images/scan.svg'
import { TerminalDocs } from '../docs/TerminalDocs'
import { PrinterModal, ScannerModal } from '../modal'

import { Equipment } from './Equipments'

export function TerminalMain({
	id,
	ip,
	terminalId,
}: {
	id: string
	ip: string
	terminalId: string
}) {
	const terminalInfo = useAppSelector(
		(state) => state.terminals.currentTerminal
	)
	const dispatch = useAppDispatch()
	const [modalPrinter, setModalPrinter] = useState<boolean>(false)
	const [modalScanner, setModalScanner] = useState<boolean>(false)
	const [statuses, setStatuses] = useState<null | any>(null)
	const [cameraType, setCameraType] = useState<'btn' | 'webrtc' | 'camera'>(
		'btn'
	)
	const [vncType, setVncType] = useState<'btn' | 'vnc'>('btn')
	const [isAppeal, setIsAppeal] = useState<boolean>(false)

	const cameraBlock: {
		[key: string]: ReactNode
	} = {
		btn: (
			<div className="terminal__main-camera-btn">
				<Button
					type="primary"
					onClick={() => {
						terminalCallAPI.receive_operator_call(terminalInfo!.terminalId)
						dispatch(setTypeCall('operator'))
						setCameraType('webrtc')
						setIsAppeal(true)
					}}
				>
					Начать сеанс связи
				</Button>
				<Button type="primary" onClick={() => setCameraType('camera')}>
					Подключиться к камере
				</Button>
			</div>
		),
		webrtc: <WebRTCSession id={id} onClose={() => setCameraType('btn')} />,
		camera: <RtspSession id={id} onClose={() => setCameraType('btn')} />,
	}

	const vncBlock: {
		[key: string]: ReactNode
	} = {
		btn: (
			<div className="terminal__main-vnc-btn">
				<Button type="primary" onClick={() => setVncType('vnc')}>
					Подключиться
				</Button>
			</div>
		),
		vnc: <VncSession onClose={() => setVncType('btn')} />,
	}

	useEffect(() => {
		const socket = new WebSocket(
			`${API_URL}/terminals/get_terminal_statuses?TerminalId=${id}`
		)

		socket.onmessage = function (event) {
			const obg = JSON.parse(event.data)
			setStatuses(obg.Value)
		}

		return () => socket.close()
	}, [])

	return (
		<>
			<div className="terminal__main-wrap">
				<div className="terminal__main-wrap-item_2">
					<div
						className={`terminal__main-camera ${cameraType !== 'btn' && 'terminal__main-camera-active'}`}
					>
						{cameraBlock[cameraType]}
					</div>
					<div className="terminal__devices-wrap">
						<Equipment
							type="Принтер"
							title="hp fssf"
							status={statuses?.devicesStatus?.printerStatus}
							icon={PrinterIcon}
							openModal={() => setModalPrinter(true)}
						/>
						<Equipment
							type="Сканер"
							title="Canon"
							status={statuses?.devicesStatus?.scanerStatus}
							icon={ScannerIcon}
							openModal={() => setModalScanner(true)}
						/>
						<Equipment
							type="KKM"
							title="АТСП"
							status={statuses?.devicesStatus?.kkmStatus}
						/>
						<Equipment
							type="Эквайринг"
							title="Ingenico"
							status={statuses?.devicesStatus?.acquiringStatus}
						/>
					</div>
				</div>
				<div className="terminal__main-wrap-item_3">
					<div
						className={`terminal__main-vnc ${vncType !== 'btn' && 'active'}`}
					>
						{vncBlock[vncType]}
					</div>
					<TerminalDocs id={id} />
					<Button
						type="primary"
						style={{ height: '50px' }}
						disabled={!isAppeal}
					>
						Зафиксировать обращение
					</Button>
				</div>
			</div>
			<Modal
				open={modalPrinter}
				title="Принтер"
				centered
				className="terminal__modal"
				onOk={() => setModalPrinter(false)}
				onCancel={() => setModalPrinter(false)}
				footer={null}
			>
				<PrinterModal name={'HP LaserJet 1050'} id={id} />
			</Modal>
			<Modal
				open={modalScanner}
				title="Сканер"
				centered
				className="terminal__modal"
				onOk={() => setModalScanner(false)}
				onCancel={() => setModalScanner(false)}
				footer={null}
				destroyOnClose={true}
			>
				<ScannerModal name={'Canon'} id={id} />
			</Modal>
		</>
	)
}
