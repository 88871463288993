import { Table, TableProps } from 'antd'
import moment from 'moment'
import { useEffect } from 'react'

import {
	documentsItem,
	getAstipDocuments,
	loadingStatus,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import { openErrorNotification } from '@/utils'

import { terminalAPI } from '@/api'

export function TerminalDocs({ id }: { id: string }) {
	const documentsList = useAppSelector(
		(state) => state.terminals.astipDocuments
	)
	const role = useAppSelector((state) => state.auth.role)
	const dispatch = useAppDispatch()

	console.log(documentsList)

	const getDoc = async (id: string) => {
		try {
			dispatch(loadingStatus(true))
			const { data } = await terminalAPI.getDocument({ DocumentId: id })
			const blob = new Blob([data])
			const link = document.createElement('a')
			const blobUrl = window.URL.createObjectURL(blob)
			link.href = blobUrl
			link.download = 'Docs.pdf'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		} catch (e) {
			console.error(e)
			openErrorNotification('Ошибка открытия документа')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const columnsAdmin: TableProps<documentsItem>['columns'] = [
		{
			title: 'Дата создания',
			render: (obj) => {
				return (
					<button onClick={() => getDoc(obj.id)}>
						{moment(obj.creationDateTime).format('YYYY-MM-DD, HH:mm:ss')}
					</button>
				)
			},
		},
		{
			title: 'Название',
			dataIndex: 'fileName',
			key: 'fileName',
		},
		{
			title: 'Тип',
			dataIndex: 'type',
			key: 'type',
		},
		{
			title: 'Оператор',
			key: 'userName',
			dataIndex: 'userName',
		},
	]
	const columns: TableProps<documentsItem>['columns'] = [
		{
			title: 'Дата создания',
			render: (obj) => {
				return (
					<button onClick={() => getDoc(obj.id)}>
						{moment(obj.creationDateTime).format('YYYY-MM-DD, HH:mm:ss')}
					</button>
				)
			},
		},
		{
			title: 'Название',
			dataIndex: 'fileName',
			key: 'fileName',
		},
		{
			title: 'Тип',
			dataIndex: 'type',
			key: 'type',
		},
	]

	useEffect(() => {
		dispatch(getAstipDocuments({ TerminalId: id }))
	}, [])

	return (
		<Table
			className="terminal__appeals-table"
			columns={role === 'Admin' ? columnsAdmin : columns}
			dataSource={documentsList}
			pagination={false}
		/>
	)
}
