import { CloseOutlined } from '@ant-design/icons'
import { useEffect, useRef } from 'react'

import { setIsCalling, setTypeCall, useAppDispatch } from '@/store'

import { WEBRTC_URL } from '@/configs'

import { terminalCallAPI } from '@/api'

import { ManagementCamera } from '../manageCamera/ManagementCamera'

interface Props {
	id: string
	onClose: () => void
}

export const WebRTCSession = ({ id, onClose }: Props) => {
	const iframeRef = useRef<HTMLIFrameElement>(null)
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(setIsCalling(true))
		const listener = (event: any) => {
			if (event.data && event.data.type) {
				// if (event.data.type === 'close-iframe') {
				// 	onClose()
				// }
			}
		}

		window.addEventListener('message', listener, false)
		return () => {
			window.addEventListener('message', listener, false)
			terminalCallAPI.end_call()
			dispatch(setIsCalling(false))
			dispatch(setTypeCall(null))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const sendMessageToIframe = () => {
		if (iframeRef.current && iframeRef.current.contentWindow) {
			iframeRef.current.contentWindow.postMessage(
				{ type: 'change-size-window', room: id },
				'*'
			)
		}
	}

	return (
		<div className="webrtc">
			<iframe
				title="webrtc"
				src={`${WEBRTC_URL}/${id}`}
				style={{ width: '100%', height: '100%' }}
				allow="camera; microphone; fullscreen"
				loading="lazy"
				ref={iframeRef}
			></iframe>
			<ManagementCamera id={id} />
			<CloseOutlined className="rtsp__close" onClick={onClose} />
		</div>
	)
}
