import { Segmented } from 'antd'
import { useState } from 'react'

import { Operators } from '@/components'

export function Administration() {
	const [value, setValue] = useState<string | number>('Операторы')

	return (
		<div className="administration">
			<div className="administration__title title">Панель администратора</div>
			<div className="administration__wrap">
				<div className="administration__top">
					<Segmented
						options={['Операторы', 'Справочники']}
						value={value}
						onChange={setValue}
					/>
				</div>
				{value === 'Операторы' && <Operators />}
			</div>
		</div>
	)
}
