import { ConfigProvider } from 'antd'
import ruRU from 'antd/locale/ru_RU'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'

import { store } from '@/store'

import MainApp from './MainApp'
import './index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: '#3690e4',
				},
				components: {
					Tabs: {
						cardBg: 'rgba(255, 255, 255, 0.8)',
						itemActiveColor: '#4195df',
						itemSelectedColor: '#fff',
						inkBarColor: '#4195df',
						colorBgContainer: '#4195df',
						colorBorderSecondary: 'transparent',
					},
					Segmented: {
						trackBg: '#fff',
						itemActiveBg: '#4195df',
						itemSelectedBg: '#4195df',
						colorBgContainer: '#4195df',
						itemSelectedColor: '#fff',
					},
				},
			}}
			locale={ruRU}
		>
			<Provider store={store}>
				<MainApp />
			</Provider>
		</ConfigProvider>
	</React.StrictMode>
)
