import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { useAppDispatch, useAppSelector } from '@/store'

import {
	openErrorNotification,
	openSuccessNotification,
	passField,
} from '@/utils'

import { usersAPI } from '@/api'

export type typeForm = {
	password: string
}

export const ChangePasswordOperator = ({
	onClose,
	userId,
	name,
}: {
	onClose: () => void
	userId: string
	name: string
}) => {
	const statusList = useAppSelector((state) => state.terminals.listStatus)
	const dispatch = useAppDispatch()

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm<typeForm>()

	const onSubmit: SubmitHandler<typeForm> = async (data) => {
		try {
			await usersAPI.newPassUser({
				UserId: userId,
				password: data.password,
			})
			reset()
			onClose()
			openSuccessNotification('Пароль обнавлен')
		} catch (e: any) {
			openErrorNotification('Ошибка изменения пароля')
		}
	}

	useEffect(() => {}, [])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div style={{ marginBottom: '10px' }}>
				Вы изменяете пароль для: {name}
			</div>
			<div className="inputs-wrap">
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="pass">
							Новый пароль
						</label>
						<Controller
							name="password"
							control={control}
							rules={passField}
							render={({ field }) => (
								<Input.Password
									{...field}
									placeholder="Ваш пароль"
									id="pass"
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
								/>
							)}
						/>
						{errors?.password && (
							<div className="input__error">
								{errors.password?.message ? errors.password?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="terminals__form-add-bottom">
				<Button htmlType="button" onClick={onClose}>
					Отмена
				</Button>
				<Button htmlType="submit" type="primary">
					Изменить
				</Button>
			</div>
		</form>
	)
}
