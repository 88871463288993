import { useEffect, useState } from 'react'
import { RouterProvider } from 'react-router-dom'

import { init, setTypeApp, useAppDispatch } from '@/store'

import { typeAppDesktop, typeAppMobile } from '@/utils'

import { usersAPI } from './api'
import router from './routes'

function MainApp() {
	const dispatch = useAppDispatch()
	const [width, setWidth] = useState<number>(window.innerWidth)

	const routers = router

	useEffect(() => {
		if (width < 769) {
			dispatch(setTypeApp(typeAppMobile))
		} else {
			dispatch(setTypeApp(typeAppDesktop))
		}

		const handleBeforeUnload = () => {
			console.log('Покидаете страницу!')
			usersAPI.switchStatus(false)
		}

		window.addEventListener('beforeunload', handleBeforeUnload)

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [dispatch, width])

	useEffect(() => {
		const handlePageFocus = () => {
			console.log('Вошли на страницу!')
			usersAPI.switchStatus(true)
		}

		const handleResize = () => setWidth(window.innerWidth)
		window.addEventListener('resize', handleResize)
		dispatch(init())

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	return <RouterProvider router={routers} />
}

export default MainApp
