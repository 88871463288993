import { Button, Select, Table, TableProps } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import {
	addNewAppeal,
	getAbonenetsList,
	setCurrentAppeals,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import { addAppeals, commentField, requiredField } from '@/utils'

import { appealsAPI } from '@/api'

type Select = {
	value: number
	label: string
}

interface DataType {
	key: string
	date: string
	name: string
	type: string
	operator: string
}

const columns: TableProps<DataType>['columns'] = [
	{
		title: 'Дата создания',
		dataIndex: 'date',
		key: 'date',
	},
	{
		title: 'Название',
		dataIndex: 'name',
		key: 'name',
	},
	{
		title: 'Тип',
		dataIndex: 'type',
		key: 'type',
	},
	{
		title: 'Оператор',
		key: 'operator',
		dataIndex: 'operator',
	},
]

const data: DataType[] = [
	{
		key: '1',
		name: 'item1',
		date: 'date 1',
		type: 'type',
		operator: 'operator',
	},
	{
		key: '2',
		name: 'item1',
		date: 'date 1',
		type: 'type',
		operator: 'operator',
	},
	{
		key: '3',
		name: 'item1',
		date: 'date 1',
		type: 'type',
		operator: 'operator',
	},
]

export function AddAppealsModal({
	terminalId,
	fetch,
}: {
	terminalId: string
	fetch: () => void
}) {
	const [dataForSelectStatus, setDataForSelectStatus] = useState<
		Array<Select> | undefined
	>(undefined)
	const [dataForTypesStatus, setDataForTypesStatus] = useState<
		Array<Select> | undefined
	>(undefined)

	const abonentList = useAppSelector((state) => state.abonents.abonentsList)
	const statusList = useAppSelector((state) => state.appeals.statusList)
	const typesList = useAppSelector((state) => state.appeals.typesList)
	const appeal = useAppSelector((state) => state.appeals.currentAppeals)

	console.log(appeal)
	const dispatch = useAppDispatch()

	const dataForSelectAbonent = abonentList?.map((i: any) => ({
		value: i.id,
		label: `Номер: ${i.personalAcc}, Почта: ${i.email}`,
	}))

	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = useForm<addAppeals>()

	const onSubmit: SubmitHandler<addAppeals> = (data) => {
		try {
			console.log(data)
			dispatch(addNewAppeal({ ...data }))
			fetch()
		} catch (e) {}
	}

	const onSearch = (value: string) => {
		dispatch(getAbonenetsList({ Filter: value }))
	}

	useEffect(() => {
		reset({
			terminalId,
		})
	}, [terminalId])

	useEffect(() => {
		if (typesList && statusList) {
			setDataForSelectStatus(
				statusList.map((e: any) => {
					return {
						value: e.id,
						label: e.status,
					}
				})
			)
			setDataForTypesStatus(
				typesList.map((e: any) => {
					return {
						value: e.id,
						label: e.type,
					}
				})
			)
		}
	}, [statusList, typesList])

	const fetch2 = async () => {
		const appeal = await appealsAPI.getAppeals(
			'6de9d03f-4d75-4635-9528-33e07fa86f9e'
		)

		dispatch(setCurrentAppeals(appeal.data))
	}

	useEffect(() => {
		fetch2()
		return () => {
			dispatch(setCurrentAppeals(null))
		}
	}, [])

	return (
		<div className="terminal__modal-inner terminal__appeals">
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="terminal__appeals-top">
					<div className="terminal__appeals-left">
						<div className="input-inner">
							<div className="input-inner__row">
								<div className="terminal__appeals-item">
									<div className="terminal__appeals-item-title">Абонент</div>
									<Controller
										name="abonentId"
										control={control}
										rules={requiredField}
										render={({ field }) => (
											<Select
												{...field}
												style={{ width: '100%' }}
												onSearch={onSearch}
												placeholder={'Выберите Абонента'}
												options={dataForSelectAbonent}
												virtual={false}
												filterOption={false}
												showSearch
											/>
										)}
									/>
									{errors?.abonentId && (
										<div className="input__error">
											{errors.abonentId?.message
												? errors.abonentId?.message
												: 'Ошибка'}
										</div>
									)}
								</div>
							</div>
							<div className="input-inner__row">
								<div className="terminal__appeals-item">
									<div className="terminal__appeals-item-title">Статус</div>
									<Controller
										name="status"
										control={control}
										rules={requiredField}
										render={({ field }) => (
											<Select
												{...field}
												style={{ width: 120 }}
												placeholder={'Выберите статус'}
												options={dataForSelectStatus}
											/>
										)}
									/>
									{errors?.status && (
										<div className="input__error">
											{errors.status?.message
												? errors.status?.message
												: 'Ошибка'}
										</div>
									)}
								</div>
								<div className="terminal__appeals-item">
									<div className="terminal__appeals-item-title">
										Тип обращения
									</div>
									<Controller
										name="type"
										control={control}
										rules={requiredField}
										render={({ field }) => (
											<Select
												{...field}
												style={{ width: 120 }}
												placeholder={'Выберите тип'}
												options={dataForTypesStatus}
											/>
										)}
									/>
									{errors?.type && (
										<div className="input__error">
											{errors.type?.message ? errors.type?.message : 'Ошибка'}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className="terminal__appeals-right">
						<div className="terminal__appeals-item">
							<div className="input">
								<div className="terminal__appeals-item-title">Комментарий</div>
								<Controller
									name="comment"
									control={control}
									rules={commentField}
									render={({ field }) => (
										<TextArea
											placeholder="Комментарий"
											autoSize={{ minRows: 2, maxRows: 6 }}
											{...field}
										/>
									)}
								/>
								{errors?.comment && (
									<div className="input__error">
										{errors.comment?.message
											? errors.comment?.message
											: 'Ошибка'}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
				<div className="terminal__appeals-bottom">
					<Button
						className="terminal__appeals-bottom-btn"
						type="primary"
						htmlType="submit"
						size="large"
					>
						Создать
					</Button>
				</div>
			</form>

			<div className="terminal__appeals-docs">
				<div className="terminal__appeals-item-title">Документы</div>
				{appeal ? (
					<>
						<Table
							className="terminal__appeals-table"
							columns={columns}
							dataSource={data}
							pagination={false}
						/>
						<Button className="terminal__appeals-docs-btn">Добавить</Button>
					</>
				) : (
					'Чтобы добавить документы создайте обращение'
				)}
			</div>
		</div>
	)
}
