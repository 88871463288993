import {
	loadingStatus,
	setAstipDocuments,
	setList,
	setListStatus,
	setTerminal,
	setTerminalConfig,
} from '..'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { terminalAPI } from '@/api'

export const getListTerminals =
	({ UserId }: { UserId?: string }) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const response = await terminalAPI.getList({
				...(UserId && { UserId }),
			})
			dispatch(setList(response.data))
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка получения списка терминалов')
			}
		}
		dispatch(loadingStatus(false))
	}

export const getAstipDocuments =
	({ TerminalId }: { TerminalId: string }) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const response = await terminalAPI.getDocuments({ TerminalId })
			if (response.data) {
				const forSave = response.data.map((e: any) => {
					return {
						...e,
						key: e.id,
					}
				})
				dispatch(setAstipDocuments(forSave))
			}
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка получения списка документов')
			}
		}
		dispatch(loadingStatus(false))
	}

export const getTerminal = (TerminalId: string) => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		const response = await terminalAPI.getTerminalInfo({ TerminalId })
		dispatch(setTerminal(response.data))
	} catch (e: any) {
		if (e.response.data) {
			openErrorNotification('Ошибка получения информации о терминале')
		}
	}
	dispatch(loadingStatus(false))
}

export const getStatusList = () => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		const response = await terminalAPI.getStatusList()
		dispatch(setListStatus(response.data))
	} catch (e: any) {
		if (e.response.data) {
			openErrorNotification(
				`Ошибка получения списка статусов. ${e.response.data.message}`
			)
		}
	}
	dispatch(loadingStatus(false))
}

export const getTerminalConfig =
	(TerminalId: string) => async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			const response = await terminalAPI.getTerminalConfig({ TerminalId })
			dispatch(setTerminalConfig(response.data))
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка получения конфига терминале')
			}
		}
		dispatch(loadingStatus(false))
	}

export const getUserTerminals = () => async (dispatch: any) => {
	dispatch(loadingStatus(true))

	try {
		const response = await terminalAPI.getUserTerminals()
		dispatch(setList(response.data))
	} catch (e: any) {
		if (e.response.data) {
			openErrorNotification('Ошибка получения информации о терминале')
		}
	}
	dispatch(loadingStatus(false))
}

export const removeScanDoc =
	({ terminalId }: { terminalId: string }) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))
		try {
			await terminalAPI.removeScanDoc({ terminalId })
		} catch (e: any) {
			console.error('Ошибка:', e)
			openErrorNotification('Ошибка')
		}
		dispatch(loadingStatus(false))
	}

export const editTerminal =
	({
		id,
		name,
		currentIP,
		astipConfigId,
	}: {
		id: string
		name: string
		currentIP: string
		astipConfigId?: string
	}) =>
	async (dispatch: any) => {
		dispatch(loadingStatus(true))

		try {
			await terminalAPI.editTerminal({
				id,
				name,
				currentIP,
				astipConfigId,
			})
			dispatch(getTerminal(id))
			dispatch(getTerminalConfig(id))
			openSuccessNotification('Основаная информация о терминале изменена')
		} catch (e: any) {
			if (e.response.data) {
				openErrorNotification('Ошибка получения информации о терминале')
			}
		}
		dispatch(loadingStatus(false))
	}
