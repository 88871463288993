import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { Button } from 'antd'
import { FC, useEffect, useState } from 'react'

import { loadingStatus, removeScanDoc, useAppDispatch } from '@/store'

import { openErrorNotification, openSuccessNotification } from '@/utils'

import { terminalAPI } from '@/api'

interface PdfViewerProps {
	id: string
}

export const PdfViewer: FC<PdfViewerProps> = ({ id }) => {
	const [pdfFile, setPdfFile] = useState<Array<string>>([])
	const [previewPdfFile, setPreviewPdfFile] = useState<string>('')
	const [isFirstFile, setIsFirstFile] = useState<boolean>(true)
	const dispatch = useAppDispatch()

	const fetchPdf = async () => {
		try {
			dispatch(loadingStatus(true))

			const response = await terminalAPI.scanDoc({ terminalId: id })
			const blob = new Blob([response.data])
			console.log(response)
			setPdfFile([...pdfFile, URL.createObjectURL(blob)])
			setPreviewPdfFile(URL.createObjectURL(blob))
			setIsFirstFile(false)
		} catch (error) {
			console.error('Ошибка при сканировании:', error)
			openErrorNotification('Ошибка при сканировании ')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	const onSaveDocs = async () => {
		try {
			dispatch(loadingStatus(true))
			await terminalAPI.saveScanDoc({ terminalId: id, appealId: null })
			openSuccessNotification('Сохранение документов успешное')
		} catch (error) {
			console.error('Ошибка при сохранение:', error)
			openErrorNotification('Ошибка при сохранение ')
		} finally {
			dispatch(loadingStatus(false))
		}
	}

	useEffect(() => {
		return () => {
			console.log(isFirstFile)
			if (isFirstFile) {
				dispatch(removeScanDoc({ terminalId: id }))
			}
			setPreviewPdfFile('')
			setPdfFile([])
			setIsFirstFile(true)
		}
	}, [])

	return (
		<>
			<Button
				className={'terminal__modal-scanner-btn'}
				size="large"
				type="primary"
				onClick={fetchPdf}
			>
				{isFirstFile ? 'Сканировать' : 'Сканировать еще'}
			</Button>
			{pdfFile.length ? (
				<>
					<div className="terminal__modal-scanner-preview">
						<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
							<Viewer
								fileUrl={previewPdfFile}
								defaultScale={SpecialZoomLevel.PageFit}
							/>
						</Worker>
					</div>
					<div className="terminal__modal-scanner-wrap">
						{pdfFile.map((e, i) => (
							<button
								key={i}
								onClick={() => setPreviewPdfFile(e)}
								className="terminal__modal-wrap-pdf-btn"
							>
								<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
									<Viewer fileUrl={e} defaultScale={SpecialZoomLevel.PageFit} />
								</Worker>
							</button>
						))}
					</div>
				</>
			) : isFirstFile ? (
				''
			) : (
				<p>Загрузка документа...</p>
			)}
			<Button
				className={'terminal__modal-scanner-btn'}
				size="large"
				onClick={onSaveDocs}
			>
				Сохранить
			</Button>
		</>
	)
}
