import {
	DeleteOutlined,
	LoginOutlined,
	QuestionCircleOutlined,
	RetweetOutlined,
} from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import {
	getStatusList,
	loadingStatus,
	useAppDispatch,
	useAppSelector,
} from '@/store'

import {
	TERMINALS_PATH,
	openErrorNotification,
	openSuccessNotification,
} from '@/utils'

import { terminalAPI } from '@/api'

import AicvairingIcon from '../../../assets/images/aicvairing.svg'
import CheckIcon from '../../../assets/images/check.svg'
import PrinterIcon from '../../../assets/images/print.svg'
import ScannerIcon from '../../../assets/images/scan.svg'

export function TerminalListItem({
	item,
	onSetStatus,
	fetch,
	statuses,
}: {
	item: any
	onSetStatus: any
	fetch: () => void
	statuses: any | null
}) {
	const role = useAppSelector((state) => state.auth.role)
	const statusList = useAppSelector((state) => state.terminals.listStatus)
	const dispatch = useAppDispatch()

	const getNameStatus = (index: number | null) => {
		let statusObj = null
		if (index) {
			statusObj = statusList?.find((item) => item.id === index)
		}

		return statusObj ? statusObj.status : 'None'
	}

	const onDeleteTerminal = async (id: string) => {
		dispatch(loadingStatus(true))

		try {
			await terminalAPI.deleteTerminal({ Id: id })
			openSuccessNotification('Успешное удаление терминала')
		} catch (e: any) {
			if (e.response?.data) {
				openErrorNotification(
					`Ошибка удаления терминала. ${e.response.data.message}`
				)
			} else {
				openErrorNotification(`Ошибка удаления терминала.`)
			}
		}
		fetch()
		dispatch(loadingStatus(false))
	}

	useEffect(() => {
		dispatch(getStatusList())
	}, [])

	return (
		<>
			<div className="terminals__name">
				<Link to={`${TERMINALS_PATH}/${item.id}`}>Имя: {item.name}</Link>
				<div
					className={`terminals__status ${getNameStatus(statuses ? statuses.status : null) === 'Online' && '_active'}`}
				></div>
			</div>
			<div className="terminals__info">
				<div className="terminals__info-title">IP-адрес: </div>
				<div className="terminals__ip">{item.currentIP}</div>
			</div>
			{role === 'Admin' && (
				<div className="terminals__manage">
					<div className="terminals__manage-title">Управление:</div>
					<div className="terminals__btns-wrap">
						<Button
							className="terminals__btn-sm"
							type="primary"
							onClick={() => onSetStatus(item.id, item.astipStatus)}
						>
							Поменять статус <RetweetOutlined />
						</Button>
						<Popconfirm
							title="Удалить терминал?"
							okText="Да"
							onConfirm={() => onDeleteTerminal(item.id)}
							cancelText="Нет"
							okButtonProps={{ danger: true }}
							icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
						>
							<Button className="terminals__btn-sm" danger type="primary">
								Удалить <DeleteOutlined />
							</Button>
						</Popconfirm>
					</div>
				</div>
			)}

			<Link to={`${TERMINALS_PATH}/${item.id}`} className="terminals__link btn">
				<span>Перейти к терминалу </span>
				<LoginOutlined style={{ fontSize: '18px' }} />
			</Link>

			<div className="terminals__devices-wrap">
				<div className="terminals__devices-title">Устройства:</div>
				<div className="terminals__devices">
					<div className="terminal__device">
						<div className="terminal__device-title-wrap">
							<img
								className="terminal__device-btn-icon"
								src={PrinterIcon}
								alt="terminal__device-btn"
							/>
							<div
								className={`terminal__device-status ${
									statuses?.devicesStatus?.printerStatus === 'active'
										? '_active'
										: ''
								}`}
							>
								{statuses?.devicesStatus?.printerStatus ? '' : 'N'}
							</div>
						</div>
					</div>
					<div className="terminal__device">
						<div className="terminal__device-title-wrap">
							<img
								className="terminal__device-btn-icon"
								src={ScannerIcon}
								alt="terminal__device-btn"
							/>
							<div
								className={`terminal__device-status ${
									statuses?.devicesStatus?.scanerStatus === 'active'
										? '_active'
										: ''
								}`}
							>
								{statuses?.devicesStatus?.scanerStatus ? '' : 'N'}
							</div>
						</div>
					</div>
					<div className="terminal__device">
						<div className="terminal__device-title-wrap">
							<img
								className="terminal__device-btn-icon"
								src={CheckIcon}
								alt="terminal__device-btn"
							/>
							<div
								className={`terminal__device-status ${
									statuses?.devicesStatus?.kkmStatus === 'active'
										? '_active'
										: ''
								}`}
							>
								{statuses?.devicesStatus?.kkmStatus ? '' : 'N'}
							</div>
						</div>
					</div>
					<div className="terminal__device">
						<div className="terminal__device-title-wrap">
							<img
								className="terminal__device-btn-icon"
								src={AicvairingIcon}
								alt="terminal__device-btn"
							/>
							<div
								className={`terminal__device-status ${
									statuses?.devicesStatus?.acquiringStatus === 'active'
										? '_active'
										: ''
								}`}
							>
								{statuses?.devicesStatus?.acquiringStatus ? '' : 'N'}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
