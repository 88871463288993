import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { userType } from '@/utils'

export type terminalItem = {
	id: string
	terminalId: string
	name: string
	currentIP: string
	astipStatus: string
	user: userType | null
}

export type terminalConfig = {
	terminalId: string
	configId: string
	config: Array<any>
}

export type documentsItem = {
	key: string
	creationDateTime: string
	type: string
	fileName: string
	id: string
	userName: string
}

interface InitialStateType {
	terminalsList: Array<terminalItem> | null
	currentTerminal: terminalItem | null
	listStatus: null | Array<{ id: number; status: string }>
	currentTerminalConfig: terminalConfig | null
	astipDocuments: undefined | Array<documentsItem>
}

const initialState: InitialStateType = {
	terminalsList: null,
	currentTerminal: null,
	listStatus: null,
	currentTerminalConfig: null,
	astipDocuments: undefined,
}

export const terminalsReducer = createSlice({
	name: 'terminals',
	initialState,
	reducers: {
		setList: (state, action: PayloadAction<Array<terminalItem>>) => {
			state.terminalsList = action.payload
		},
		setTerminal: (state, action: PayloadAction<terminalItem | null>) => {
			state.currentTerminal = action.payload
		},
		setListStatus: (
			state,
			action: PayloadAction<Array<{ id: number; status: string }>>
		) => {
			state.listStatus = action.payload
		},
		setTerminalConfig: (
			state,
			action: PayloadAction<terminalConfig | null>
		) => {
			state.currentTerminalConfig = action.payload
		},
		setAstipDocuments: (state, action: PayloadAction<Array<documentsItem>>) => {
			state.astipDocuments = action.payload
		},
	},
})

export const {
	setList,
	setTerminal,
	setListStatus,
	setAstipDocuments,
	setTerminalConfig,
} = terminalsReducer.actions

export default terminalsReducer.reducer
