import {
	DownOutlined,
	LeftOutlined,
	RightOutlined,
	UpOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'

import { loadingStatus, useAppDispatch } from '@/store'

import { openErrorNotification } from '@/utils'

import { terminalAPI } from '../../api'

export function ManagementCamera({ id }: { id: string }) {
	const dispatch = useAppDispatch()

	const managerCamera = async (direction: number) => {
		dispatch(loadingStatus(true))

		try {
			await terminalAPI.moveCamera({ terminalId: id, direction })
		} catch (e: any) {
			openErrorNotification(`Ошибка метода управлением камеры`)
			console.error(e)
		}
		dispatch(loadingStatus(false))
	}

	return (
		<>
			<div className="management">
				<div className="management__item">
					<Button
						className="management__btn"
						onClick={() => managerCamera(3)}
						shape="circle"
						icon={<UpOutlined />}
					></Button>
				</div>
				<div className="management__item">
					<Button
						className="management__btn"
						onClick={() => managerCamera(1)}
						shape="circle"
						icon={<LeftOutlined />}
					></Button>
					<Button
						className="management__btn"
						onClick={() => managerCamera(2)}
						shape="circle"
						icon={<RightOutlined />}
					></Button>
				</div>
				<div className="management__item">
					<Button
						className="management__btn"
						onClick={() => managerCamera(4)}
						shape="circle"
						icon={<DownOutlined />}
					></Button>
				</div>
			</div>
		</>
	)
}
