import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Input } from 'antd'
import { useEffect } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { useAppDispatch, useAppSelector } from '@/store'

import {
	emailField,
	loginField,
	newUserType,
	openErrorNotification,
	openSuccessNotification,
	passField,
} from '@/utils'

import { usersAPI } from '@/api'

export const AddNewOperator = ({
	onClose,
	fetch,
}: {
	onClose: () => void
	fetch: () => void
}) => {
	const statusList = useAppSelector((state) => state.terminals.listStatus)
	const dispatch = useAppDispatch()

	const {
		register,
		handleSubmit,
		reset,
		control,
		formState: { errors },
	} = useForm<newUserType>()

	const onSubmit: SubmitHandler<newUserType> = async (data) => {
		try {
			await usersAPI.addUser({
				userName: data.userName,
				email: data.email,
				password: data.password,
			})
			reset()
			fetch()
			onClose()
			openSuccessNotification('Оператор добавлен')
		} catch (e: any) {
			openErrorNotification('Ошибка добавление оператора')
		}
	}

	useEffect(() => {}, [])

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className="inputs-wrap">
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="userName">
							Имя
						</label>
						<Controller
							name="userName"
							control={control}
							rules={loginField}
							render={({ field }) => (
								<Input placeholder="Имя" id="userName" {...field} />
							)}
						/>
						{errors?.userName && (
							<div className="input__error">
								{errors.userName?.message ? errors.userName?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="email">
							E-mail
						</label>
						<Controller
							name="email"
							control={control}
							rules={emailField}
							render={({ field }) => (
								<Input placeholder="Введите почту" id="email" {...field} />
							)}
						/>
						{errors?.email && (
							<div className="input__error">
								{errors.email?.message ? errors.email?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
				<div className="inputs-wrap__row">
					<div className="input">
						<label className="input__label" htmlFor="password">
							Пароль
						</label>
						<Controller
							name="password"
							control={control}
							rules={passField}
							render={({ field }) => (
								<Input.Password
									{...field}
									placeholder="Пароль"
									id="password"
									iconRender={(visible) =>
										visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
									}
								/>
							)}
						/>
						{errors?.password && (
							<div className="input__error">
								{errors.password?.message ? errors.password?.message : 'Ошибка'}
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="terminals__form-add-bottom">
				<Button htmlType="button" onClick={onClose}>
					Отмена
				</Button>
				<Button htmlType="submit" type="primary">
					Добавить
				</Button>
			</div>
		</form>
	)
}
