import settingsIcon from '../../../assets/images/settings.svg'

interface Props {
	type: string
	title: string
	status: any
	icon?: string
	openModal?: () => void
}

export const Equipment = ({ type, title, status, icon, openModal }: Props) => {
	return (
		<div className="terminal__device" style={{ minWidth: 150 }}>
			<div className="terminal__device-type">{type}</div>
			<div className="terminal__device-title-wrap">
				<div className="terminal__device-title">{title}</div>
				<div
					className={`terminal__device-status ${status === 'active' ? '_active' : ''}`}
				>
					{status ? '' : 'N'}
				</div>
			</div>
			<div className="terminal__device-bottom">
				{icon && (
					<button className="terminal__device-btn" onClick={openModal}>
						<img
							className="terminal__device-btn-icon"
							src={icon}
							alt="terminal__device-btn"
						/>
					</button>
				)}
				<button className="terminal__device-btn">
					<img
						className="terminal__device-btn-icon"
						src={settingsIcon}
						alt="terminal__device-btn"
					/>
				</button>
			</div>
		</div>
	)
}
